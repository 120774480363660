import React from "react";

export const ReturnPolicy = () => {
    return (
        <>
            <div className="terms-container">
                <center>
                    <h1 className="terms-title">Return Policy for Pethood</h1>
                    <hr className="terms-divider" />
                </center>
                <div>
                    <p>At Pethood, we prioritize customer satisfaction and aim to ensure that all products meet the highest quality standards. However, in the event that a customer receives a defective or incorrect product, we provide a return option under the following conditions:</p>

                    <h2 className="terms-side">Eligibility for Return</h2>
                    <ul>
                        <li>Products are eligible for return if they are:</li>
                        <ul>
                            <li>Defective or damaged upon delivery.</li>
                            <li>Incorrect items delivered, such as wrong size, type, or product.</li>
                        </ul>
                        <li>Returns must be initiated within 7 days of receiving the product.</li>
                        <li>The product should be in its original packaging and unused to qualify for a return.</li>
                    </ul>

                    <h2 className="terms-side">Non-Returnable Items</h2>
                    <p>Certain items cannot be returned for safety and hygiene reasons, including:</p>
                    <ul>
                        <li>Perishable goods like pet food, treats, supplements, and hygiene products.</li>
                        <li>Personalized or customized products are also non-returnable unless they are defective.</li>
                    </ul>

                    <h2 className="terms-side">Return Process</h2>
                    <p>To initiate a return, pet owners must contact the respective pet store owner via the App.</p>
                    <ul>
                        <li>The pet store owner will verify the request and guide the customer through the return process.</li>
                        <li>Once the return is approved, the customer will receive instructions on how to send the product back.</li>
                    </ul>
                </div>
            </div>
        </>
    )
}