import React from "react";

const Dangerhtml = () => {
  const rawHtml = `
    <div style="padding: 20px; font-family: Arial;">
      <h1>Privacy Policy</h1>
      <p>
        Welcome to Pethood! Your privacy is important to us. This Privacy Policy
        explains how we collect, use, and protect your personal information
        when you use our app.
      </p>
      <h2>Information We Collect</h2>
      <ul>
        <li>
          <strong>Account Information:</strong> Name, email address, phone
          number, and other details provided during registration.
        </li>
        <li>
          <strong>Location Data:</strong> To provide location-based services
          like nearby pet shops or veterinarians.
        </li>
        <li>
          <strong>Usage Data:</strong> Information about how you use the app.
        </li>
      </ul>
      <h2>How We Use Your Information</h2>
      <p>
        We use your information to provide and improve our services, including:
      </p>
      <ul>
        <li>Personalizing your experience.</li>
        <li>Connecting you with pet care services.</li>
        <li>Improving app functionality.</li>
      </ul>
      <h2>Data Security</h2>
      <p>
        We take appropriate measures to protect your data from unauthorized
        access, alteration, or disclosure.
      </p>
      <h2>Your Rights</h2>
      <p>
        You have the right to access, update, or delete your personal
        information. Contact us if you have any concerns.
      </p>
      <h2>Contact Us</h2>
      <p>
        If you have questions about this Privacy Policy, please contact us at
        <a href="mailto:support@pethood.com">support@pethood.com</a>.
      </p>
    </div>
  `;

  return (
    <div>
      <div dangerouslySetInnerHTML={{ __html: rawHtml }} />
    </div>
  );
};

export default Dangerhtml;

