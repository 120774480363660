import React from "react";

export const RefundPolicy = () => {
    return (
        <>
            <div className="terms-container">
                <center>
                    <h1 className="terms-title">Refund Policy for Pethood</h1>
                    <hr className="terms-divider" />
                </center>
                <div>
                    <h2 className="terms-side">Eligibility for Refund</h2>
                    <ul>
                        <li>Refunds are issued only after the returned product has been received and inspected by the petstoreowner.</li>
                        <li>The product must meet the return conditions to qualify for a refund.</li>
                    </ul>

                    <h2 className="terms-side">Refund Process</h2>
                    <ul>
                        <li>Once the return is approved, the refund will be processed to the original payment method (credit card, debit card, UPI, etc.).</li>
                        <li>The refund process may take 7-10 business days after approval, depending on the payment provider.</li>
                    </ul>

                    <h2 className="terms-side">Non-Refundable Cases</h2>
                    <ul>
                        <li>Returns that do not comply with the Return Policy (e.g., products returned without original packaging or used items) will not be eligible for a refund.</li>
                        <li>Products damaged due to misuse by the petowner are non-refundable.</li>
                    </ul>

                    <h2 className="terms-side">Order Cancellation</h2>
                    <ul>
                        <li>Pet Owners can cancel their orders before the product is shipped by the pet store owner.</li>
                    </ul>
                </div>
            </div>
        </>
    )
}