import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import PageOTP from "./Pages/PageOTP";
import "./App.css";
import ForgotPassword from "./Pages/ForgotPassword";
import Landingpage from "./Pages/Landingpage";
import Mobilepage from "./Pages/Mobilepage";
import Dog from "./Pages/Dog";
import BrandPage from "./Pages/BrandPage";
import AboutPage from "./Pages/AboutPage";
import ContactUs from "./Pages/ContactUs";
import TermsAndConditions from "./Pages/TermsAndConditions";
import FAQs from "./Pages/FAQs";
import { Aboutus } from "./Pages/Aboutus";
import { PrivacyPolicy } from "./Pages/PrivacyPolicy";
import { RefundPolicy } from "./Pages/RefundPolicy";
import { ReturnPolicy } from "./Pages/ReturnPolicy";
import Login from "./Pages/Login";
import { LoginForm } from "./Pages/LoginForm";
import { AllUsers } from "./Pages/AllUsers";
import Subscriptions from "./Pages/Subscriptions";
import TopNavBar from "./Pages/TopNavBar";
import { AllFeedBacks } from "./Pages/AllFeedBacks";
import PostReports from "./Pages/PostReports";
import Dangerhtml from "./Pages/Dngeorhtml";
const App = () => {
  return (
    <Router>
      <div className="app">
        <TopNavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/pageotp" element={<PageOTP />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/landingpage" element={<Landingpage />} />
          <Route path="/mobilepage" element={<Mobilepage />} />
          <Route path="/dog" element={<Dog />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/brand" element={<BrandPage />} />
          <Route path="/terms-conditions" element={<TermsAndConditions />} />
          <Route path="/faq" element={<FAQs />} />
          <Route path="/Aboutus" element={<Aboutus />} />
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/RefundPolicy" element={<RefundPolicy />} />
          <Route path="/ReturnPolicy" element={<ReturnPolicy />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/LoginForm" element={<LoginForm />} />
          <Route path="/AllUsers" element={<AllUsers />} />
          <Route path="/Subscriptions" element={<Subscriptions />} />
          <Route path="/AllFeedBacks" element={<AllFeedBacks />} />
          <Route path="/PostReports" element={<PostReports />} />
          <Route path="/Dangerhtml" element={<Dangerhtml />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
