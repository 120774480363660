import React, { useState, useEffect } from "react";
import axios from "axios";
import apiconfig from "../Config/Config";
import { DataGrid } from "@mui/x-data-grid";
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from "@mui/material";

const PostReports = () => {
  const [reportedPosts, setReportedPosts] = useState([]);
  const [selectedPost, setSelectedPost] = useState(null);
  const [error, setError] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const avdurl1 = apiconfig.develpoment.apibaseUrl;

  const handleAccept = async (reportId) => {
    try {
      const response = await axios.delete(`${avdurl1}admin/delete-reported-post?report_id=${reportId}`);
      console.log(response.data);
      setReportedPosts((prevPosts) => prevPosts.filter((post) => post.report_id !== reportId));
    } catch (err) {
      setError("Error deleting reported post and associated post");
      console.error(err);
    }
  };


  const handleViewDetails = async (reportId) => {
    try {
      const response = await axios.get(avdurl1 + `admin/reported-post?report_id=${reportId}`);
      setSelectedPost(response.data); // Set the selected post details to state
      setOpenModal(true); // Open the modal
    } catch (err) {
      setError("Error fetching post details");
      console.error(err);
    }
  };

  // Fetch all reported posts
  useEffect(() => {
    const fetchReportedPosts = async () => {
      try {
        const response = await axios.get(avdurl1 + "admin/reported-posts");
        setReportedPosts(response.data); // Set the fetched data to state
      } catch (err) {
        setError("Error fetching reported posts");
        console.error(err);
      }
    };

    fetchReportedPosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    { field: "report_id", headerName: "Report ID", width: 120 },
    { field: "reasonforreporting", headerName: "Reason for Reporting", width: 300 },
    { field: "post_description", headerName: "Post Description", width: 300 },
    { field: "user_name", headerName: "Reported Username", width: 200 },
    {
      field: "post_image_url",
      headerName: "Post Image",
      renderCell: (params) => (
        <img src={params.value[0]} alt="Post" width="50" height="50" style={{ borderRadius: "5px" }} />
      ),
      width: 100,
    },
    {
      field: "action",
      headerName: "Actions",
      renderCell: (params) => (
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleAccept(params.row.report_id)}
            style={{ marginRight: 8 }}
          >
            Accept
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleViewDetails(params.row.report_id)}
          >
            View Details
          </Button>
        </div>
      ),
      width: 250,
    },
  ];

  const rows = reportedPosts.map((post) => ({
    id: post.report_id,
    report_id: post.report_id,
    reasonforreporting: post.reasonforreporting,
    reported_at: post.reported_at,
    user_name: post.user_name,
    post_image_url: post.post_image_url,
    post_description: post.post_description,
  }));

  console.log('reportedPosts', reportedPosts);
  return (
    <div className="users-container">
      <h1>Reported Posts</h1>
      {error && <p style={{ color: "red" }}>{error}</p>}

      {/* DataGrid for displaying reported posts */}
      {/* <div style={{ height: 600, width: "100%" }}> */}
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[5, 10, 20]}
        disableSelectionOnClick
      />
      {/* </div> */}

      {/* Modal to show post details */}
      <Dialog open={openModal} onClose={() => setOpenModal(false)}>
        <DialogTitle>Post Details</DialogTitle>
        <DialogContent>
          {selectedPost && selectedPost.length > 0 ? (
            <div>
              <p><strong>Reason for Reporting:</strong> {selectedPost[0]?.reasonforreporting}</p>
              <p><strong>Post Type:</strong> {selectedPost[0]?.post_type}</p>
              <p><strong>Reported At:</strong> {new Date(selectedPost[0]?.reported_at).toLocaleString('en-IN', {
                timeZone: 'Asia/Kolkata',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit'
              })}</p>
              <p><strong>User Name:</strong> {selectedPost[0]?.user_name}</p>
              <p><strong>Pet ID:</strong> {selectedPost[0]?.pet_id}</p>
              {selectedPost[0]?.post_image_url?.map((url, index) => (
                <img key={index} src={url} alt="Reported Post" width="100%" />
              ))}
              <p><strong>Post Description:</strong> {selectedPost[0]?.post_description}</p>
              <p><strong>User Type:</strong> {selectedPost[0]?.user_type}</p>
              <p><strong>Likes:</strong> {selectedPost[0]?.post_likes?.length}</p>
              <p><strong>Comments:</strong> {selectedPost[0]?.post_comments || "No comments"}</p>
            </div>
          ) : (
            <p>Loading post details...</p>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModal(false)} color="default">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PostReports;
