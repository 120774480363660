import React from "react";

export const PrivacyPolicy = () => {

    return (
        <>
            <div className="terms-container">
                <center>
                    <h1 className="terms-title">Privacy Policy for Pethood</h1>
                    <hr className="terms-divider" />
                </center>
                <div>
                    <p>
                        Welcome to Pethood ("App"). This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our App, in compliance with the applicable laws of India and the General Data Protection Regulation (GDPR) for users within the European Union.
                    </p>
                   <h2 className="terms-side">1. Introduction</h2>
                    <p >
                        This Privacy Policy is an electronic record in terms of the Information Technology Act, 2000 and rules made thereunder, as amended.
                    </p>

                   <h2 className="terms-side">2. Information We Collect</h2>
                    <ul >
                        <li >
                            <strong>Personal Information:</strong> Name, phone number, email address, address, city, and any other details you provide voluntarily during registration.
                        </li>
                        <li >
                            <strong>Role-Specific Information:</strong>
                            <ul >
                                <li >
                                    <strong>Pet Doctor:</strong> Clinic Name, Services, Years of Experience, and Medical Certificate.
                                </li>
                                <li >
                                    <strong>Pet Trainer:</strong> Skills, Years of Experience, and Skills Certificate.
                                </li>
                                <li >
                                    <strong>Pet Store Owner:</strong> Business Name, Products Sold, and Shop Certificate.
                                </li>
                            </ul>
                        </li>
                        <li >
                            <strong>Tracking Information:</strong> IP address, device ID, and browsing data for analytics and improvement of user experience.
                        </li>
                    </ul>

                   <h2 className="terms-side">3. Use of Information</h2>
                    <p >
                        We use the information collected to provide, personalize, and enhance your experience.
                    </p>

                   <h2 className="terms-side">4. Data Security</h2>
                    <p >
                        We implement industry-standard security measures to safeguard your data.
                    </p>

                   <h2 className="terms-side">5. Cookies and Tracking Technologies</h2>
                    <p >
                        We use cookies to personalize content and improve the App. You may set your browser to refuse cookies.
                    </p>

                   <h2 className="terms-side">6. Third-Party Services</h2>
                    <p >
                        Our App may contain links to third-party sites or services.
                    </p>

                   <h2 className="terms-side">7. Disclosure of Information</h2>
                    <p >
                        We may share your information only with your consent or to fulfill services.
                    </p>

                   <h2 className="terms-side">8. Data Retention and Deletion</h2>
                    <p >
                        Your personal information will be retained only as necessary.
                    </p>

                   <h2 className="terms-side">9. User Rights</h2>
                    <p >
                        Depending on your location, you may have certain rights regarding your personal data.
                    </p>

                   <h2 className="terms-side">10. Amendments to This Policy</h2>
                    <p >
                        We may update our Privacy Policy periodically.
                    </p>

                   <h2 className="terms-side">11. Contact Us</h2>
                    <ul>
                        <li>
                            <strong>Email:</strong> onelovepc2021@gmail.com
                        </li>
                        <li>
                            <strong>Support:</strong> +919875757979
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
};
