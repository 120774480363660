import React from "react";
import "./TermsAndConditions.css";

const TermsAndConditions = () => {
  return (
    <div className="terms-container">
      <center>
        <h1 className="terms-title">Terms and Conditions for Pethood</h1>
        <hr className="terms-divider" />
      </center>
      <div>
        <h2 className="terms-side">Introduction</h2>
        <p>By accessing or using Pethood ('App'), you agree to be bound by these Terms and Conditions. If you do not agree with these terms, please refrain from using the App.</p>

        <h2 className="terms-side">User Accounts</h2>
        <p>You must create an account to access certain features of the App. You are responsible for maintaining the confidentiality of your account information.</p>

        <h2 className="terms-side">Roles and Features</h2>
        <ul>
          <li><strong>Petowner:</strong> Pet owners can add pets, view available pet services, and find partners for their pets (trainers, doctors, stores).</li>
          <li><strong>Pet Doctor and Pet Trainer:</strong> Doctors and trainers can manage their schedules, accept or decline appointments, and update their availability.</li>
          <li><strong>Pet Store Owner:</strong> Store owners can add products, manage categories, and receive orders from pet owners. They also have the option to bulk upload products using .csv or Excel files.</li>
        </ul>

        <h2 className="terms-side">User Conduct</h2>
        <p>You agree to use the App for lawful purposes and not engage in any activities that violate any applicable laws or regulations.</p>

        <h2 className="terms-side">Payments and Orders</h2>
        <p>Pet owners can place orders for services or products, and pet store owners will receive and manage those orders. Payments must be made via the provided payment systems.</p>

        <h2 className="terms-side">Limitation of Liability</h2>
        <p>We are not responsible for any damages or losses resulting from your use of the App or third-party services.</p>

        <h2 className="terms-side">Amendments</h2>
        <p>We reserve the right to amend these Terms and Conditions at any time. You will be notified of any significant changes.</p>

        <h2 className="terms-side">Contact Us</h2>
        <p>For questions regarding the Terms and Conditions, please contact us via email or support number.</p>

        <ul>
          <li><strong>Email:</strong> onelovepc2021@gmail.com</li>
          <li><strong>Support:</strong> +919875757979</li>
        </ul>
      </div>
    </div>
  );
};

export default TermsAndConditions;
